<template>
  <Layout>
    <PageHeader :title="state.title" :items="state.items"/>
    <div class="card rounded-3">
      <div class="card-header d-flex justify-content-between align-items-center border-top">
        <h6 class="fs-16 mb-0">{{ $t('playDiscs.play-discs-management') }}</h6>
        <div class="flex" style="column-gap: 8px;">
          <b-button variant="primary" class="waves-effect waves-light d-flex align-items-center"
                    @click="createPlayDiscs" :disabled="state.isLoading">
            <Loading v-if="state.isLoading" :width="14" :height="14" class="mr-2"/>
            <div class="flex" v-if="state.isCreate">
              {{ $t("t-register-btn") }}
            </div>
            <div class="flex" v-else>
              {{ $t("t-renew") }}
            </div>
          </b-button>
        </div>

      </div>

      <div v-if="state.loadingTotal" class="py-3 px-5 d-flex align-items-center justify-content-center label mt-3">
        <Loading/>
      </div>

      <div v-if="!state.loadingTotal">
        <div class="px-5">
          <div class="row py-3 d-flex align-items-center label mt-3" style="row-gap: 10px">
            <div class="col-md-3">
              <div class="d-flex">
                <label class="text-nowrap mb-0 mr-2" style="padding-top: 10px">プレイリスト名 <span
                  class="text-danger">*</span></label>
                <div class="w-75" style="height: 63px">
                  <input style="max-width: 300px" v-model="state.payload.name" maxlength="255"
                         :placeholder="$t('playDiscs.enter-play-discs')" type="text" @focus="handleFocus"
                         class="form-control">
                  <div v-if="state.formError.name !== ''" class="text-danger mt-2" style="font-size: 11px">
                    {{ state.formError.name }}
                  </div>
                  <div v-if="state.formError.dupName" class="text-danger mt-2" style="font-size: 11px">
                    {{ $t(state.formError.dupName) }}
                  </div>
                </div>

              </div>

            </div>

            <div class="col-md-3">
              <div class="d-flex">
                <label class="text-nowrap mb-0 mr-2" style="padding-top: 10px">広告名 <span
                  class="text-danger">*</span></label>
                <div class="w-100" style="height: 63px">
                  <Multiselect
                    :close-on-select="true"
                    :placeholder="$t('playDiscs.select-ads')"
                    class="multiselect-input"
                    style="padding: 0; max-width: 300px; margin: 0"
                    :options="state.optionsAds"
                    :track-by="'name'"
                    :label="'name'"
                    :searchable="true"
                    :can-clear="false"
                    :can-deselect="false"
                    :object="true"
                    @change="changeSelectAds"
                    v-model="state.selectedAds"
                    ref="selectAdsRef"
                    :multiple="true"
                    :mode="'multiple'"
                    @select="checkCondition"
                  >
                    <template #noresults>
                      <div class="d-flex justify-content-center m-2">{{ $t('t-no-data') }}</div>
                    </template>
                    <template #nooptions>
                      <div class="d-flex justify-content-center m-2">{{ $t('t-no-data') }}</div>
                    </template>
                  </Multiselect>
                  <!--                  <v-select @option:selected="changeSelectAds" ref="selectAdsRef" :track-by="'name'" :clearable="false"
                                      :label="'name'" style="padding: 0; max-width: 300px; margin: 0" :placeholder="$t('playDiscs.select-ads')"
                                    :options="state.optionsAds" class="multiselect-input style-chooser">
                                    <template #no-options>{{ $t('t-no-data')
                                    }}</template>
                                  </v-select>-->
                  <div v-if="state.formError.adsId !== ''" class="text-danger mt-2" style="font-size: 11px">
                    {{ state.formError.adsId }}
                  </div>
                  <div v-if="state.formError.adsExist !== ''" class="text-danger mt-2" style="font-size: 11px">
                    {{ state.formError.adsExist }}
                  </div>
                </div>

              </div>

            </div>

            <div class="col-md-4">
              <div class="d-flex">
                <div class="w-100 m-auto" style="height: 63px;padding-top: 13px">
                  <input v-model="state.payload.state" :disabled="disabled" class="form-check-input mr-2 check-box"
                         type="checkbox" value=""
                         aria-label="Checkbox for following text input">
                  <label class="text-wrap">残り時間を無視して、リストに登録されている広告をループ再生する</label>
                </div>
              </div>
            </div>

            <div class="col-md-2 d-flex justify-content-end">
              <div>
                <div class="d-flex col-gap-10">
                  <span>セット時間</span>
                  <span>{{ state.time.totalMin }} 分</span>
                </div>
                <div class="d-flex  col-gap-10">
                  <span>設定済時間</span>
                  <span>{{ state.time.recent }} 秒</span>
                </div>
                <div class="d-flex  col-gap-10">
                  <span>残り時間</span>
                  <span>{{ state.time.remain }} 秒</span>
                </div>
              </div>
            </div>
          </div>
          <div class="px-3" style="min-height: 200px; max-height: 500px; overflow-x: auto">
            <table class="table align-middle table-nowrap mb-0 table-hover table-bordered">
              <thead class="table-light">
              <tr>
                <th scope="col" data-sort="currency_name">並順</th>
                <th scope="col" data-sort="currency_name">広告ID</th>
                <th scope="col" data-sort="currency_name">広告名</th>
                <th scope="col" data-sort="currency_name">広告プレビューURL</th>
                <th scope="col" data-sort="currency_name">カテゴリ</th>
                <th scope="col" data-sort="currency_name">再生時間</th>
                <th scope="col" data-sort="currency_name"></th>
              </tr>
              </thead>
              <draggable @change="changePosition" v-model="state.selectedAds" :list="state.selectedAds" tag="tbody"
                         v-bind="dragOptions">
                <tr v-for="(row, index) in state.selectedAds" :key="index">
                  <td data-bs-toggle="tooltip" data-bs-placement="bottom"
                      class="text-truncate fs-12 fw-medium text-normal">{{ ++index }}
                  </td>
                  <td data-bs-toggle="tooltip" data-bs-placement="bottom"
                      class="text-truncate fs-12 fw-medium text-normal">{{ row.adsId }}
                  </td>
                  <td data-bs-toggle="tooltip" data-bs-placement="bottom" :title="row.adsName"
                      class="text-truncate fs-12 fw-medium text-normal" style="max-width: 40vw">{{ row.adsName }}
                  </td>
                  <td data-bs-toggle="tooltip" data-bs-placement="bottom"
                      class="text-truncate fs-12 fw-medium text-normal" :title="row.urlAfter">
                    <a :href="row.urlAfter" target="_blank">{{ sliceFileName(row.url, 5, 20) }}</a>
                  </td>
                  <td data-bs-toggle="tooltip" data-bs-placement="bottom"
                      class="text-truncate fs-12 fw-medium text-normal">{{ ADS_TYPE[row.type] }}
                  </td>
                  <td data-bs-toggle="tooltip" data-bs-placement="bottom"
                      class="text-truncate fs-12 fw-medium text-normal">{{ row.appearanceTime }} 秒
                  </td>
                  <td>
                    <b-button variant="danger" @click="deleteAds(index)" class="r waves-effect waves-light">
                      削除
                    </b-button>
                  </td>
                </tr>
              </draggable>
            </table>
          </div>
          <hr class="dash"/>
          <div class="d-flex col-md-5 p-0" style="height: 67px">
            <label class="text-nowrap mb-0 mr-2" style="padding-top: 13px">施設名</label>
            <div style="column-gap: 21px;" class="d-flex w-100 flex-column">
              <Multiselect :placeholder="$t('playDiscs.select-facilities')" v-model="state.selectedFacilities"
                           class="multiselect-input" style="padding: 0; max-width: 350px; margin: 0"
                           :options="state.optionsFacilities"
                           :track-by="'name'" :label="'name'" :multiple="true" :searchable="true" :can-clear="false"
                           :can-deselect="false" :object="true" :mode="'multiple'"
                           @change="addFacility"

              >
                <template #noresults>
                  <div class="d-flex justify-content-center m-2">{{ $t('t-no-data') }}</div>
                </template>
                <template #nooptions>
                  <div class="d-flex justify-content-center m-2">{{ $t('t-no-data') }}</div>
                </template>
              </Multiselect>
              <div class="text-danger mt-2" style="font-size: 11px">
                {{ state.formError.facilityExist }}
              </div>
            </div>
            <!--v-if="state.formError.facilityExist !== ''"-->

          </div>
          <div class="row py-3 px-3" style="min-height: 200px; max-height: 500px; overflow-x: auto">
            <div class="col-12 col-lg-6 m-0">
              <table class="table align-middle table-nowrap mb-0 table-hover table-bordered">
                <thead class="table-light">
                <tr>
                  <th class="sort" data-sort="currency_name" scope="col"></th>
                  <th class="sort" data-sort="currency_name" scope="col">
                    <div class="d-flex w-100 justify-content-between align-items-center">
                      <div>施設名称</div>
                      <div style="width: 24px" @click="sort('facName')">
                        <div class="cursor-pointer" v-if="querySearch.sortBy !== 'facName'">
                          <i class="ri-expand-up-down-fill font-16" style="color: #acadaf"></i>
                        </div>
                        <div class="d-flex flex-row" v-else>
                          <i
                            class="ri-arrow-up-s-fill cursor-pointer d-flex align-items-center justify-content-center font-16"
                            style="padding: 0 4px; " :class="getClassActive('facName', 'asc')"/>
                          <i style="padding: 0 4px;"
                             class="ri-arrow-down-s-fill cursor-pointer d-flex align-items-center justify-content-center font-16"
                             :class="getClassActive('facName', 'desc')"/>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th class="sort" data-sort="currency_name" scope="col">
                    <div class="d-flex w-100 justify-content-between align-items-center">
                      <div>施設ID</div>
                      <div style="width: 24px" @click="sort('facUuid')">
                        <div class="cursor-pointer" v-if="querySearch.sortBy !== 'facUuid'">
                          <i class="ri-expand-up-down-fill font-16" style="color: #acadaf"></i>
                        </div>
                        <div class="d-flex flex-row" v-else>
                          <i
                            class="ri-arrow-up-s-fill cursor-pointer d-flex align-items-center justify-content-center font-16"
                            style="padding: 0 4px; " :class="getClassActive('facUuid', 'asc')"/>
                          <i style="padding: 0 4px;"
                             class="ri-arrow-down-s-fill cursor-pointer d-flex align-items-center justify-content-center font-16"
                             :class="getClassActive('facUuid', 'desc')"/>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th class="sort" data-sort="currency_name" scope="col">
                    <div class="d-flex w-100 justify-content-between align-items-center">
                      <div>カテゴリ</div>
                      <div style="width: 24px" @click="sort('des')">
                        <div class="cursor-pointer" v-if="querySearch.sortBy !== 'des'">
                          <i class="ri-expand-up-down-fill font-16" style="color: #acadaf"></i>
                        </div>
                        <div class="d-flex flex-row" v-else>
                          <i
                            class="ri-arrow-up-s-fill cursor-pointer d-flex align-items-center justify-content-center font-16"
                            style="padding: 0 4px; " :class="getClassActive('des', 'asc')"/>
                          <i style="padding: 0 4px;"
                             class="ri-arrow-down-s-fill cursor-pointer d-flex align-items-center justify-content-center font-16"
                             :class="getClassActive('des', 'desc')"/>
                        </div>
                      </div>
                    </div>
                  </th>

                  <th class="sort" data-sort="currency_name" scope="col"></th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(row, index) in state.selectedFacilities" :key="index">
                  <td data-bs-toggle="tooltip" data-bs-placement="bottom"
                      class="text-truncate fs-12 fw-medium text-normal">{{ ++index }}
                  </td>
                  <td data-bs-toggle="tooltip" data-bs-placement="bottom" :title="row.facName" style="max-width: 40vw"
                      class="text-truncate fs-12 fw-medium text-normal">{{ row.facName }}
                  </td>
                  <td data-bs-toggle="tooltip" data-bs-placement="bottom"
                      class="text-truncate fs-12 fw-medium text-normal">{{ row.facUuid }}
                  </td>
                  <td data-bs-toggle="tooltip" data-bs-placement="bottom"
                      class="text-truncate fs-12 fw-medium text-normal">{{ row.des }}
                  </td>
                  <td>
                    <b-button variant="danger" @click="deleteFacility(index)" class="r waves-effect waves-light">
                      削除
                    </b-button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>

    </div>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import {onMounted, reactive, ref, watch, onBeforeMount} from "vue";
import i18n from "@/i18n";
import Multiselect from "@vueform/multiselect";
import {VueDraggableNext} from 'vue-draggable-next'
import {cloneDeep, delay, get, orderBy, sumBy} from "lodash";
import {adsService} from "@/services";
import {ADS_TYPE} from "@/constants"
import {generateImageOss, sliceFileName} from "@/helpers/axios/formDataRequest"
import {useRoute, useRouter} from "vue-router";
import store from "@/state/store";
import Loading from "@/components/Loading.vue";

export default {
  name: "create",
  components: {
    Layout,
    PageHeader,
    Multiselect,
    draggable: VueDraggableNext,
    Loading
  },
  setup() {
    const selectAdsRef = ref(null)
    const state = reactive({
      maxSelectAds: -1,
      title: i18n.global.t('playDiscs.play-discs-management'),
      items: [
        {
          text: i18n.global.t('t-ads-management'),
          href: "/admin/play-discs",
        },
        {
          text: i18n.global.t('playDiscs.play-discs-management'),
          active: true,
        },
      ],
      optionsAds: [],
      optionsFacilities: [],
      selectAds: null,
      selectedAds: [],
      selectedFacilities: [],
      payload: {
        name: '',
        facilitiesId: [],
        adsId: [],
        state: false
      },
      formError: {
        name: '',
        adsId: '',
        dupName: '',
        adsExist: '',
        facilityExist: '',
      },
      time: {
        totalMin: 10,
        total: 600,
        recent: 0,
        remain: 600
      },
      isCreate: true,
      isLoading: false,
      loadingTotal: false,
      currentSelectAds: {},
    })
    const route = useRoute();
    const router = useRouter();

    onBeforeMount(() => {
      getDataForUpdate();
    })

    onMounted(() => {
      setAdsOptions();
      setFacilitiesOptions();
    })


    const getDataForUpdate = async () => {
      state.isCreate = !route.params.playlistId;
      if (!route.params.playlistId) return;

      try {
        state.loadingTotal = true
        const res = await adsService.getDetailPlaylist(route.params.playlistId);
        state.payload.name = res.name;
        const tempSelectAds = []

        for (let i = 0; i < res.advertisements.length; i++) {

          if (res.advertisements[i]) {
            const temp = {
              ...res.advertisements[i],
              urlAfter: generateImageOss(res.advertisements[i].url)
            }

            tempSelectAds.push(temp);
          }
        }
        state.payload.state = res.state
        state.selectedAds = cloneDeep(tempSelectAds);
        state.selectedFacilities = res.facilities;

      } catch (e) {
        console.log(e, 'get info playlist error')
      } finally {
        state.loadingTotal = false
      }
    }

    const setFacilitiesOptions = async () => {
      try {
        //  const res = await adsService.getFacilitiesOptionsPlayList({ facType: 'NOT_ASSIGNED' });
        const res = await adsService.getFacilitiesOptionsPlayListNew();
        state.optionsFacilities = state.optionsFacilities.concat(res);
        delay(() => {
          state.optionsFacilities = state.optionsFacilities.concat(state.selectedFacilities);
        }, 10)
        resetList()
      } catch (e) {
        console.log(e)
      }
    }

    watch(() => state.payload.name, () => {
      state.formError.name = state.payload.name.length === 0 ? i18n.global.t('field_required', {field: 'プレイリスト名'}) : '';
    })

    const handleFocus = () => {
      state.formError.dupName = ''
    }

    const calculatorTime = () => {
      state.time.recent = sumBy(state.selectedAds, 'appearanceTime');

      state.time.remain = state.time.total - state.time.recent;
    }

    watch(() => state.selectedAds, (value) => {
      if (state.selectedAds.length === 0) {
        state.formError.adsId = i18n.global.t('field_select_required', {field: '広告名'});
      }
      if (totalTimeSelected(value) > 600) {
        state.selectedAds = state.selectedAds.filter(item => item.id !== state.currentSelectAds.id);
      }
      calculatorTime();
    }, {deep: true})

    const validateForm = () => {
      state.formError.name = state.payload.name.length === 0 ? i18n.global.t('field_required', {field: 'プレイリスト名'}) : '';
      state.formError.adsId = state.selectedAds.length === 0 ? i18n.global.t('field_select_required', {field: '広告名'}) : '';
    }

    const setAdsOptions = async () => {
      try {
        const res = await adsService.getAdsOptionsPlaylist();

        state.optionsAds = [];
        for (let i = 0; i < res.length; i++) {
          const temp = {
            ...res[i],
            urlAfter: generateImageOss(res[i].url)
          }

          state.optionsAds.push(temp);
        }
      } catch (e) {
        console.log(e, '=======')
      }
    }

    const createPlayDiscs = async () => {
      const tempAdsIds = [];
      const tempFacilityIds = []
      state.selectedAds.map((item) => {
        tempAdsIds.push(item.id);
      })

      state.selectedFacilities.map((item) => {
        tempFacilityIds.push(item.value);
      })


      state.payload['adsId'] = tempAdsIds;
      state.payload['facilitiesId'] = state.selectedFacilities?.filter(e => e.type == 'facility').map(e => e.id);
      state.payload['roomsId'] = state.selectedFacilities?.filter(e => e.type == 'room').map(e => e.id);
      validateForm();
      const validate = Object.values(state.formError).some(item => item.length > 0);
      if (!validate) {
        try {
          state.isLoading = true
          let message = '';
          if (state.isCreate) {
            await adsService.createPlaylist(state.payload);
            //await router.push({name: 'play-discs-management'})
            message = 'msg.saved'
          } else {
            await adsService.updatePlaylist(route.params.playlistId, state.payload);
            message = 'msg.saved'
          }

          store.commit('settings/SET_POPUP', {
            show: true,
            type: 'success',
            message: i18n.global.t(message)
          });
        } catch (e) {
          let message = get(e, 'response.data.message', '')
          if (message.toLowerCase().includes('incorrect')) {
            message = state.payload.facilitiesId.length > 0 ? 'msg.MSG_076' : 'msg.MSG_075'
          }
          if (message == '無効になっている広告があります。') {
            state.formError.adsExist = message;
          } else if (message == '無効になっている施設があります。') {
            state.formError.facilityExist = message;
          } else {
            state.formError.dupName = message;
          }
        } finally {
          state.isLoading = false
        }
      }
    }

    const dragOptions = {
      scrollSensitivity: 200,
      forceFallback: true,
      scrollSpeed: 30,
    }

    const changePosition = (value) => {
      const temp = cloneDeep(state.selectedAds);
      const index = temp.findIndex(item => item.value === value.moved.element.value)
      temp.splice(index, 1)
      temp.splice(value.moved.newIndex, 0, value.moved.element);
      state.selectedAds = cloneDeep(temp)
    }

    const deleteAds = (index) => {
      state.formError.adsExist = '';
      state.selectedAds.splice(index - 1, 1);
      if (totalTimeSelected(state.selectedAds) > 600) {
        state.formError.adsId = '残り広告表示時間が足りなくて、この広告が設定できません。';
      } else {
        state.formError.adsId = ''
      }
    }
    const deleteFacility = (index) => {
      state.formError.facilityExist = '';
      state.selectedFacilities.splice(index - 1, 1)
      resetList()
    }

    const totalTimeSelected = (value) => {
      return sumBy(value, 'appearanceTime')
    }

    const changeSelectAds = (value) => {
      if (totalTimeSelected(value) > 600) {
        state.formError.adsId = '残り広告表示時間が足りなくて、この広告が設定できません。';
      }
    }

    const checkCondition = (option) => {
      state.currentSelectAds = option;
    }
    const sort = (name) => {
      let typeDESC = true;
      if (querySearch.sortBy === name) {
        typeDESC = !(querySearch.sortOrder === 'desc')
      }
      querySearch.sortBy = name;
      querySearch.sortOrder = typeDESC ? 'desc' : "asc";

      const temp = orderBy(state.selectedFacilities, [querySearch.sortBy], [querySearch.sortOrder]);
      state.selectedFacilities = cloneDeep(temp)
    }

    const querySearch = reactive({
      sortBy: '',
      sortOrder: ''
    })

    const getClassActive = (key, type) => {
      let display = 'd-block';
      if (querySearch.sortBy === key && querySearch.sortOrder !== type) {
        display = "d-none";
      }

      return display;
    }

    const addFacility = (value) => {
      const newestAdded = cloneDeep(value[value.length - 1])
      switch (newestAdded.type) {
        case 'facility': {
          if (value.find(e => e.type === 'room' && e.facUuid === newestAdded.facUuid)) {
            value.pop()
          }
        }
          break;
        case 'room': {
          if (value.find(e => e.type === 'facility' && e.facUuid === newestAdded.facUuid)) {
            value.pop()
          }
        }
          break;
      }

      state.selectedFacilities = value
      resetList()

    }

    const resetList = () => {
      state.optionsFacilities = state.optionsFacilities.map(option => {
        let disabled = false
        switch (option.type) {
          case 'facility': {
            if (state.selectedFacilities.find(e => e.type === 'room' && e.facUuid === option.facUuid)) {
              disabled = true
            }
          }
            break;
          case 'room': {
            if (state.selectedFacilities.find(e => e.type === 'facility' && e.facUuid === option.facUuid)) {
              disabled = true
            }
          }
            break;
        }

        return {...option, disabled: disabled}
      })
    }


    return {
      state,
      dragOptions,
      ADS_TYPE,
      addFacility,
      sliceFileName,
      createPlayDiscs,
      changePosition,
      deleteAds,
      deleteFacility,
      setFacilitiesOptions,
      changeSelectAds,
      sort,
      getClassActive,
      querySearch,
      handleFocus,
      selectAdsRef,
      checkCondition
    }
  }
}
</script>

<style lang="scss" scoped>
.border-top {
  border-top: none !important;
  border-radius: 10px 10px 0 0;
}

.col-gap-10 {
  column-gap: 10px;
}

.dash {
  margin: 2rem 0;
  stroke-width: 1px;
  stroke: #C7CED4;
  border-bottom: thin #C7CED4 dashed;
}

.check-box {
  margin-top: 1px;
}

.style-chooser:deep {
  & .vs__search {
    &::placeholder {
      color: #878a9a !important;
      font-weight: 600 !important;
      opacity: 1 !important;
    }
  }

  & .vs__search,
  .vs__selected {
    font-size: .85rem;
    margin-left: 4px;
    word-wrap: break-word;
    word-break: break-all;
  }

  & .vs__dropdown-toggle {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
</style>
